import PaymentForm from './PaymentForm';
import Container from '../../components/Container';
import FormWrap from '../../components/FormWrap';

  


const Payment = () => {


    return (<>
    <Container>
        <FormWrap>
   <PaymentForm  /> 
        </FormWrap>
    </Container>
    
    </>  );
}
 
export default Payment;