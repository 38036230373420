import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/nav';
import CartProvider from './context/ModalContext';
import { Toaster } from 'react-hot-toast';
import Footer from './components/footer/Footer';
import { Provider } from 'react-redux';
import { setupStore } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore()


root.render(
  <Provider store={store}>
  <BrowserRouter>
      <div className={`relative text-black-700`}>
        <CartProvider>
        <Toaster toastOptions={{style:{background:"rgb(51 65 85)", color:"#fff"}}} />
        <div className='flex flex-col min-h-screen'>
          <NavBar />
          <div className='flex flex-wrap min-w-[320px] justify-center'>
          <App />
          </div>
          <Footer />
        </div>
        </CartProvider>
        </div>
  </BrowserRouter>
  </Provider>
);

