import React from "react";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import "../Pagination.css";

const PaginationControls = ({
    totalPosts,
    postsPerPage,
    setCurrentPage,
    currentPage,
}:any) => {
    let pages:any = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i);
    }

    let page=pages.filter((el:any)=>el===currentPage)
    let allpage=Math.ceil(totalPosts / postsPerPage)
    

    function next(page:any){
        let prev=Math.ceil(totalPosts / postsPerPage)
        if(prev>=page){
            setCurrentPage(++page)

        }else{
            return
        }
    }
    function prev(page:any){
        if(page>1){
            setCurrentPage(--page)

        }else{
            return
        }
    }




   
    return (
        <div className='pagination'>

                     <button disabled={page<=1} className={`relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white`} onClick={()=>prev(page)}><GrLinkPrevious /></button>
                  {/* {page>1 && <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"  >...</button>} */}
                    <button
                        onClick={() => setCurrentPage(page)}
                        className={"active"}>
                   {page}
                    </button>
                   <button className="relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"  >из</button>
                     <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"  >{allpage? allpage:1}</button>
                   {/* {page<allpage && <button className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"  >...</button>} */}
         
                    <button disabled={page>=allpage} className="relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300
         hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white" onClick={()=>next(page)} ><GrLinkNext /></button>

        </div>
    );
};

export default PaginationControls;