import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import axios from "../../axios";


const ClientForm = ({item}:any) => {

    const [data, setData]=useState<any>()
    const [error, setError]=useState<any>()

    useEffect(()=>{
        OrderId()
       
    },[item])


    async function OrderId(){

     try{
        if(item !==undefined){
            const res=await axios.get(`orders/getOneOrder/${item}`)
           await setData(res.data)
        }
     }  catch (error) {
      setError(error as Error)

    }
      
    }
    


    return ( <>
    {data?.length===0 && <div>
        <h4 className="text-[15px] flex items-center text-center justify-center font-semibold">
        Ваш заказ не найден</h4>
        <p className="flex items-center justify-center text-center">Попробуйте сделать новый заказ или связитись с оператором</p> </div>}
    {data?.map((item:any, i:number)=><div key={i}>
    <h4 className="text-[15px] flex items-center text-center justify-center"><p className="font-semibold mr-2">Номер Заказа: </p><span className="text-[13px]">{item.order_id}</span></h4>
        <div className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mt-5">

<div className="col-span-2 justify-self-start">Товар</div>
<div className="justify-self-center">Размер</div>
<div className="justify-self-center">Кол</div>
<div className="justify-self-center">Цена</div>

</div>
        {item.product?.map((item:any, i:any)=>{
                return <div key={i} className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mb-5 mt-3" >
                    <div className="col-span-2 justify-self-start">{item.prodname}</div>
                    <div className=" justify-self-center">{item.prodsize}</div>
                    <div className="justify-self-center">{item.prodquantity}{item.prodweight}</div>
                    <div className="justify-self-end">{item.prodprice}₽</div>

                </div>
            })}
               <h4 className="text-[15px] flex items-center justify-center"><p className="font-semibold mr-2">Итог за Заказ: </p><span className="text-[15px]">{item.price}₽</span></h4>
        <div className="mt-3">{item.ready===false && <>Ваш заказ не подтвержден подождите пока оператор связяаться с вами или позвоните по номеру +7-910-564-10-10</>}</div>
        <div className="mt-3">{(item.ready===true && item.completed===false) && <>Ваш заказ  в обработке если у вас есть вопросы можете позвоните по номеру +7-910-564-10-10</>}</div>
        <div className="mt-3">{item.completed===true &&  <>Ваш заказ завершен!!! Спасибо за покупки</> }</div>

    </div>)}

    <div>
                    <Link to={'/'} className="text-slate-500 flex items-center gap-2 mt-2">
                        <MdArrowBack/>
                        <span>Вернуться на главную</span>
                    </Link>
                </div>
    
    </> );
}
 
export default ClientForm;