
import Container from "../../components/Container";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Adminpage = () => {

    const [admin, setAdmin]=useState<any>(null)
    useEffect(()=>{
        const cartItem:any=localStorage.getItem('admin');
        const cProducts:any=JSON.parse(cartItem)
        setAdmin(cProducts)
    },[])

    const router=useNavigate()


    return (
    <Container>
        {admin && <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-2 mt-5 md:items-center">
        <div onClick={()=>router('/Admin/AdminProduct')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Продукты</div> 
       <div onClick={()=>router('/Admin/AdminOrders')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]"> Заказы</div>
             <div onClick={()=>router('/Admin/AdminPromo')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Добавить Промокод</div>
         
        </div>
        }

    </Container> );
}
 
export default Adminpage;