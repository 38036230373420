
import { useCart } from "../../hooks/useCart";
import {Link} from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import Button from "../../components/Button";
import ItemContent from "./ItemContent";
import { useNavigate } from "react-router-dom";


const CartClient = () => {

    const {cartProducts,cartTotalAmount, handleClearCart}=useCart()
    const router=useNavigate()


    if(!cartProducts || cartProducts.length===0){
        return(
            <div className="flex flex-col items-center">
                <div className="text-2xl">Ваша Корзина Пуста</div> 
                <div>
                    <Link to={'/'} className="text-slate-500 flex items-center gap-2 mt-2">
                        <MdArrowBack/>
                        <span>Начните заказывать</span>
                    </Link>
                </div>
            </div>
        )
    }

    return ( <div>

            <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">КОРЗИНА</h1>

       <div className="grid grid-cols-5 text-xs gap-4 pb-2 items-center mt-10">

        <div className="col-span-2 justify-self-start">Товар</div>
        <div className="justify-self-center">Цена</div>
        <div className="justify-self-center">Количество</div>
        <div className="justify-self-end">Итого</div>
       </div>
       <div>
            {cartProducts && cartProducts.map((item:any)=>{
                return <ItemContent key={item?.id} item={item}/>
            })}
        </div>
            <div className="border-t-[1.5px] border-slate-200 py-4 flex justify-between gap-4">
                <div className="w-[150px] mt-2">
                    <Button label="Очистить Корзину" onClick={()=>handleClearCart()} small outline/>
                </div>
                <div className="text-sm flex text-[15px] flex-col gap-1 items-start ">
                    <div className="flex justify-between w-full text-base font-semibold mt-4">
                    <span>Общии Итог </span>
                    <span>{cartTotalAmount} ₽</span>
                    </div>
                    <p className="text-slate-500">Налоги и доставка рассчитываются при оформлении заказа</p>
                    <Button label="Оформить заказ" onClick={()=>{router('/orders')}}/>
                    <Link to={'/'} className="text-slate-500 flex items-center gap-2 mt-2">
                        <MdArrowBack/>
                        <span>Продолжить заказывать</span>
                    </Link>
                </div>
            </div>
    </div> );
}
 
export default CartClient;