
import Container from '../../components/Container';
import FormWrap from '../../components/FormWrap';
import { PiCheckCircleBold } from 'react-icons/pi';
import {useNavigate} from 'react-router-dom';
import Button from '../../components/Button';


const  PaySuccesForm = ({item,res}:any) => {

    const router=useNavigate()

   
   

    return (

        <Container>
            <FormWrap>
            <div><PiCheckCircleBold size={80}/></div>
            {item?.status === 'waiting_for_capture' || "succeeded" ? <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-2 ">Заказ Оплачен</h1>:<h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-2 ">Проблемы с оплатой</h1>}
            <h3 className="flex flex-col gap-1 items-center text-center w-full text-base mt-[-10px]  md:text-lg">Скоро с вами связаться наш оператор</h3>
            <h4 className="flex flex-col gap-1 items-center text-center w-full text-[15px] mt-[-10px]  md:text-lg">Номер вашего заказа: {res}</h4>
             <Button onClick={()=>router('/')} label="Вернуться на главную"/>
            
            </FormWrap>
        </Container>
    );
}

export default PaySuccesForm;