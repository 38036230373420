
import { CartProductType } from "../../models";
import { useState } from "react";







const ProductImage = ({cartProduct, product, handleIamgeSelected}) => {

const [ind,setInd]=useState(0)


    return (<div className="grid grid-cols-6 gap-2 h-full max-h-[300px] min-h-[300px] sm:min-h-[400px]">

        <div className="flex flex-col items-center justify-center gap-4  cursor-pointer border h-full max-h-[500px] min-h-[300px] sm:min-h-[400px] ">
          {product?.images?.map((item, i)=>{
            return <div key={i} onClick={()=>{handleIamgeSelected(item); setInd(i)}}  className={`relative w-[70%] aspect-square rounded border-teal-300 ${cartProduct?.images === item ? "border-[2px]":"border-none"} `}>
                <img     src={item} alt="image"  className="object-contain" />

            </div>
          })}
        </div>
        <div className="col-span-5 relative aspect-square">
            <img src={product?.images[ind]} className="w-[300px] h-full object-contain max-h-[500px] min-h-[300px] sm:min-h-[400px]" alt={cartProduct?.name} />

        </div>

    </div> );

}
 
export default ProductImage;