
import {Link, useNavigate} from "react-router-dom";
import { CartProductType } from "../../models";
import {  truncateText1 } from "../../utils/truncateText";
import SetQuantity from "../../components/products/SetQuantity";
import { useCart } from "../../hooks/useCart";
import toast from "react-hot-toast";


interface ItemContentProps{
    item:CartProductType
}


const ItemContent:React.FC<ItemContentProps> = ({item}) => {

    const {handleRemoveProductFromCart, handleCartQtyInCrase, handleCartQtyDeCrase}=useCart()
    const router=useNavigate()

    function mek(){
        toast.success("Товар Удален")
        router('/cart')
        
    }


    return ( <div className="grid grid-cols-5 text-xs md:text-sm gap-4 border-t-[1.5px] border-slate-200 py-4 items-center">

        <div className="col-span-2 justify-self-start flex gap-2 md:gap-4">
            <Link to={`/product/${item?.id}`}>
                <div className="relative w-[40px] aspect-square">
                    <img  src={item?.images[0]} alt={item?.name}  className="object-contain"/>
                </div>
            </Link>
            <div className="flex flex-col justify-between">
            <Link to={`/product/${item?.id}`}>
                <div className="text-[10px] mt-1 ml-1">{ truncateText1(item?.name)}</div>
            </Link>
                <div className="w-[80px] ml-1">
                    <button onClick={()=>{handleRemoveProductFromCart(item); mek()}} className="text-slate-500 underline text-[10px]">Удалить</button>
                </div>
            
            </div>
        </div>
        <div className="justify-self-center text-[9px]">{item?.price}<span className="justify-self-center"> ₽/{item?.brand}</span></div>
        <div className="mr-10  justify-self-center">
            <SetQuantity cartCounter={true} cartProduct={item} handleQtyIncrase={()=>handleCartQtyInCrase(item) } handleQtyDecrase={()=>handleCartQtyDeCrase(item)} />
        </div>
        <div className="justify-self-end font-semibold text-[10px]">{item?.price * item?.quantity}<span className="ml-1">₽</span></div>


    </div> );
}
 
export default ItemContent;