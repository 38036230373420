


const AboutPages = () => {
    return ( <div className="grid drid-cols-1 mx-auto w-[380px] md:w-[70%] mt-10">

<h1 className="flex flex-col gap-1 ml-6 md:items-center w-full text-base font-semibold md:text-lg mt-4 ">ИП Степаян Мушег Степанович</h1>

<h2 className="flex flex-col gap-1 mb-5 ml-12  md:mb-0 md:ml-0  md:items-center w-full text-base font-semibold md:text-lg mt-4 ">ИНН: 500805502173</h2>
<div className="ml-2  md:mx-auto md:px-8 md:py-12 flex flex-col gap-1  md:flex-row items-center justify-evenly w-[300px] md:w-full">
            <img
                                src={"/images/photo22.jpg"}
                                width={520}
                                height={980}
                                alt="Logo"
                            />

            </div>

            

    </div>  );
}
 
export default AboutPages;