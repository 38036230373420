import axios from '../../axios';
import PaySuccesForm from './PaySuccesForm'
import { useEffect, useState } from 'react';


const Paysucsses = () => {

  
    const [item,setItem] =useState()
    const res = localStorage.getItem('id')

 
    useEffect(()=>{
   
   
  async function fechData(){
      try {
         
        const response = await  axios.get(`payment/checkStatus/${res}`
       
        ).then((res)=>setItem(res?.data));
        
    
    
      } catch (error) {
        console.error(error)
      }
  }
       
        fechData()
        
        localStorage.setItem("myOrders", JSON.stringify([res]))
    
  },[])
  
    return ( <>
    <PaySuccesForm item={item} res={res}/>
    </> );
}
 
export default Paysucsses;