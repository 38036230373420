
import Container from "../../components/Container";
import FormWrap from "../../components/FormWrap";
import OrdersForm from "./OrdersForm";
import { useEffect } from "react";




const Orders =  () => {


 

    let ordernumber=Date.now()
useEffect(()=>{

    localStorage.setItem('id',JSON.stringify(ordernumber))
},[ordernumber])
    return ( <Container>
        <FormWrap>
            <OrdersForm number={ordernumber} />
        </FormWrap>
        
    </Container> );
}
 
export default Orders;