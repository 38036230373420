import {Link} from "react-router-dom";
import Container from "../Container";
import FooterList from "./FooterList";
import {MdFacebook} from 'react-icons/md';
import { SlSocialVkontakte } from "react-icons/sl";
import { FaTelegram } from "react-icons/fa";

const Footer = () => {

    const sam=["Саморезы", "Анкера", "Болты","Гвозди","Расходные материалы","Инструменты"]


    return (  
        <footer className="bg-slate-700  md:w-full  text-slate-200 text-sm mt-16">

            <Container>
                <div className="flex flex-col  md:flex-row justify-between pt-16 pb-8">
                    <FooterList>
                        <h3 className="text-base font-bold mb-2">Категорий Товаров</h3>
                        {sam.map((item:any, i:any )=><li key={i}>{item}</li>)}
                
                    </FooterList>
                    <FooterList>
                        <h3 className="text-base font-bold mb-2">Информация о Нас</h3>
                        <Link to="/Privacy">Политика конфиденциальности</Link>
                        <Link to="#">Наши Контакты</Link>
                        <span>Тел: +7 (910)-564-10-10</span>
                        <Link to="#">Наши Магазины</Link>
                        <span>г.Егоревск ул. Александра Невского 2</span>
                        <span>г.Егоревск ул. Антипов 48</span>
                       
                   
                    </FooterList>
                    <div className="w-[360px]  md:w-1/3 mb-6 md:mb-0">
                    <h3 className="text-base font-bold mb-2">О Нас</h3>
                    <p className="mb-2">
                    Магазин «ИнcКреп» более десяти лет занимается продажей крепежных металлических изделий и инструментов.
                    Магазин располагает ассортиментом, который охватывает практически всю крепежную продукцию, производимую в мире на сегодняшний день: заклёпки, гайки, шайбы, шпильки, болты, винты, штифты, анкера, саморезы, шурупы, гвозди, крепежные пластины, перфорированные уголки, перфоленты и так далее. Мы также предлагаем и реализуем специальный крепеж, который эффективно применять в условиях повышенных нагрузок и агрессивных средах.
                    </p>
                    <p>&copy; {new Date().getFullYear()} Крепеж. Все права защищены</p>
                    </div>
                    <FooterList>
                    <h3 className="text-base font-bold mb-2 ">Подписывайтесь на Нас</h3>
                        <div className="flex gap-2 ">
                        <Link to="#"><MdFacebook size={24}/></Link>
                        <Link to="#"><SlSocialVkontakte size={24}/></Link>
                        <Link to="#"><FaTelegram size={24}/></Link>
                        </div>
                        <div className="mt-2">
                            <p>
                                Сайт используеться cookies
                            </p>
                        </div>
                        <Link to="/About">Наш Магазин</Link>
                    </FooterList>
                </div>
            </Container>
        </footer>
    );
}
 
export default Footer;