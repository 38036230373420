
const HomeBanner = () => {
    return ( 
        <div className="relative bg-gradient-to-r from-red-500 to-red-700 mb-6">
            <div className="mx-auto px-8 py-12 flex flex-col gap-2  md:flex-row items-center justify-evenly"> 
            <div className="mb-6 md:mb-0 text-center">
                <h1 className="text-4xl md:text-6xl font-bold text-white mb-4" >Сезонная распродажа</h1>
                <p className="text-lg md:text-xl text-white mb-2">Скидки на все товары</p>
                <p className="text-2xl md:text-5xl font-bold text-yellow-400">Скидка до 40% </p>
            </div>
            <img
                                src={"/images/d1.png"}
                                width={220}
                                height={180}
                                alt="Logo"
                            />

            </div>
        </div>
     );
}
 
export default HomeBanner;