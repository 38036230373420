import { useEffect, useState } from "react";
import Container from "../../components/Container";
import FormWrap from "../../components/FormWrap";
import ClientForm from "./ClientForm";



const Clientorder = () => {

    const [item, setItem]=useState<any>()

    useEffect(()=>{
        const prod:any=localStorage.getItem("myOrders")
        const prod1=JSON.parse(prod)
        setItem(prod1)
    },[])


    return ( 
        <Container>
        <FormWrap>
            <ClientForm item={item} />
        </FormWrap>
        
    </Container>
     );
}
 
export default Clientorder;