
import {Link} from "react-router-dom";
import Container from "../Container";
import CartCount from "./CartCount";
import { useNavigate, useSearchParams } from 'react-router-dom'


const NavBar = () => {
   
    const router=useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    function Naving(){
        searchParams.getAll('/');
        if(window.location.pathname==="/"){
            window.location.reload()

        }
    }

    return ( 
        <div
            className="sticky top-0 md:w-full bg-slate-300 z-30 shadow-sm"
        >
            <div className="py-4 border-b-[1px]">
                <Container>
                    <div className="flex items-center justify-between gap-3 md:gap-0">
                        <Link to='/' onClick={()=>Naving()} >
                            
                            <img
                                src={"/images/logo1.png"}
                                width={180}
                                height={200}
                                alt="Logo"
                            />
                        
                        </Link>
                        <div className="hidden md:block md:text-[20px]">ИНСТРУМЕНТ-КРЕПЕЖ</div>
                        <div className="mr-7 flex items-start gap-8 md:gap-12">
                            <CartCount/>
                        </div>


                    </div>
                </Container>
            </div>
        </div>
     );
}
 
export default NavBar;