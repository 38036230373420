import { useEffect, useState } from "react";
import Input from "../../components/inputs/input";
import { FieldValues, useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../hooks/useCart";
import Loading from '../../components/Loading';
import axios from "../../axios";
// import { API_URL } from "../../config";


const OrdersForm = (number: any) => {

    const { cartTotalAmount } = useCart()
    const [isLoading, setISLoading] = useState(false)
    const[promo,setPromo]=useState<any>('')
    const [allPromo, setAllPromo] = useState([]);
    const [sale, setSale] = useState<any>("");
    const [loading1, setLoading1]=useState(false)
    const [deliver, setDeliver] = useState(false)
    const [user, setUser] = useState<any>({
        orderid: number,
        name: "",
        firstname: "",
        phone: "",
        cartTotalAmount: cartTotalAmount,
        deliveri: deliver,
        ready: false,
        completed: false
    })
     useEffect(() => {
        getData();
      }, []);
      async function getData() {
        const res = await axios.get(`users/getPromo`);
        setAllPromo(res.data);
      }
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user))

    }, [])
  useEffect(()=>{
if(promo){
    allPromo?.map((el:any)=>{if(el.name === promo){
        
      
        setSale(el.sale)
    }})
}
  
  },[promo])



    const { register, handleSubmit, formState: { errors } } = useForm<FieldValues>({
        defaultValues: {
            name: '',
            firstname: '',
            phone: '',
        }
    })
    const router = useNavigate()

    const onsubmit = (data: any) => {

        setUser((prev: any) => {
            let updatedUser;
            if (prev) {
                updatedUser = [
                    data, user.cartTotalAmount = sale?(cartTotalAmount-(cartTotalAmount*sale)/100):cartTotalAmount, user.deliveri = deliver
                ]
            }
            localStorage.setItem('user', JSON.stringify(updatedUser))
            router('/Cashsucces')


            return updatedUser
            
        })


        setLoading1(true)

    }
    const onsubmit1 = (data: any) => {

        setUser((prev: any) => {
            let updatedUser;
            if (prev) {
                updatedUser = [
                    data, 
                    user.cartTotalAmount = sale?(cartTotalAmount-(cartTotalAmount*sale)/100):cartTotalAmount,
                     user.deliveri = deliver
                ]
            }

            localStorage.setItem('user', JSON.stringify(updatedUser))
            router('/Payment')


            return updatedUser
        })
       
        setLoading1(true)
    }
    function cashing(cash: string) {
        if (cash === "cash") {
            setISLoading(false)
        } else {
            const data = {
                id: `${number.number}`,
                price: sale?(cartTotalAmount-(cartTotalAmount*sale)/100):cartTotalAmount
            }
            
            setISLoading(true)

        }

    }
    function delivering(delivering: string) {
        if (delivering === "deliveri") {
            setDeliver(true)
        } else {
            setDeliver(false)
        }
    }









    return (<>
        {loading1?<><Loading/></>:<>
        <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-2 ">Оформления Заказа</h1>
        <hr className="bg-slate-300 w-full h-px" />
        <p className="flex items-center text-center text-base md:text-lg mt-2 ">Заказы сделанные после 18:00 рассмотреться до 11:00 следущего дня кроме выходных дней!</p>
        <hr className="bg-slate-300 w-full h-px" />
        <Input id="name" label='Имя'  register={register} errors={errors} required />
        <Input id="firstname" label='Фамилия'  register={register} errors={errors} required />
        <Input id="phone" label='Телефон' register={register} errors={errors} required type="tel" />
        <p className="text-sm md:text-lg text-center"> Если вы виберите Наличный способ оплаты наш оператор должен связаться с вами или заказ не будет  оформлен</p>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="flex w-full items-center ">
            <div className="flex justify-center items-center w-[50%]">
                <label className="text-[16px] md:text-lg" htmlFor="delivery">Заберу сам</label>
                <input className="ppeer w-5 h-5 ml-3 p-4 pt-2 outline-none pg-white font-light border-2 rounded-md transition" checked={!deliver} type="radio" id="nodelivery" name="deliveri" value="nodelivery" onChange={() => delivering("nodeliveri")} />
            </div>
            <div className="flex justify-center items-center w-[50%]">
                <label className="text-[16px] md:text-lg" htmlFor="delivery">Доставка</label>
                <input className="peer w-5 h-5 ml-3 p-4 pt-2 outline-none pg-white font-light border-2 rounded-md transition" checked={deliver} onChange={() => delivering("deliveri")} type="radio" id="deliveri" name="deliveri" value="deliveri" />
            </div>
        </div>
        {deliver ? <>
            <h3 className="flex flex-col gap-1 items-center w-full text-base font-semibold mt-4  md:text-lg">Адресс Доставки</h3>
            <Input id="city" label='Город, село'  register={register} errors={errors} required />
            <Input id="strit" label='район, улица' register={register} errors={errors} required />
            <Input id="home" label='дом, квартира' register={register} errors={errors} required />
        </> : null}
        <hr className="bg-slate-300 w-full h-px" />
        <h3 className="flex flex-col mx-auto gap-1 items-center w-full text-base font-semibold mt-4  md:text-lg">Способ Оплаты</h3>
        <div className="flex w-full items-center ">
            <div className="flex justify-center items-center w-[50%]">
                <label className="text-[16px] md:text-lg" htmlFor="cash">Наличными</label>
                <input className="peer w-5 h-5 ml-3 p-4 pt-2 outline-none pg-white font-light border-2" checked={!isLoading} type="radio" id="cash" name="cash" value="cash" onChange={() => cashing("cash")} />
            </div>
            <div className="flex justify-center items-center w-[50%]">
                <label className="ext-[16px] md:text-lg" htmlFor="cash">Безналичный</label>
                <input className="peer w-5 h-5 ml-3 p-4 pt-2 outline-none pg-white font-light border-2 rounded-md transition" checked={isLoading} onChange={() => cashing("nocash")} type="radio" id="nocash" name="nocash" value="nocash" />
            </div>
        </div>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="flex justify-start items-center w-full">
        <h3 className="text-center mb-4 items-center mr-4  mt-4  md:text-lg">Промокод:</h3>
        <input  className=" p-2 pt-1 outline-none pg-white font-light border-2 rounded-md w-[60%]"
          type="text"
          onChange={(e) => {
            setPromo(e.target.value);
          }}/>
        </div>
        <hr className="bg-slate-300 w-full h-px" />
        <h3 className="flex flex-col gap-1 items-center w-full text-base font-semibold mt-4  md:text-lg">Итого</h3>
        <div className="flex flex-col w-full">
            <div className="flex"><p className="font-semibold mr-3">Итог по заказам : </p>{cartTotalAmount} ₽</div>
            <div className="flex  mt-2 ">
                <span className="font-semibold mr-4">Доставка:</span>
                {cartTotalAmount > 5000 ? "по городу Егорьевск у вас бесплатная доставка" : " доставка считаеться отдельно 50 рублей за 1 километр"}</div>
                {sale && <p className="mt-4">  <span className="mr-2 text-base font-semibold mt-4">Скидка: {sale} % </span>{(cartTotalAmount * sale)/100} ₽</p>}
            <div className="flex w-full text-base font-semibold mt-4">
         
                <span className="mr-2">Общии Итог : </span>
                {sale ? <span className="text-base font-semibold">{cartTotalAmount-(cartTotalAmount * sale)/100} ₽</span>:<span>{cartTotalAmount} ₽</span>
                }
            </div>
        </div>

        <hr className="bg-slate-300 w-full h-px" />
        {!isLoading && <Button label="Закончить Оформления" onClick={handleSubmit(onsubmit)} />}
        {isLoading && <Button label="Перейти к Оплате" onClick={handleSubmit(onsubmit1)} />}

        </>}
    </>);
}

export default OrdersForm;