import { useEffect, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { MdCheckBox, MdClose, MdEditSquare } from "react-icons/md";
import Container from "../../components/Container";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";

const AdminPromo = () => {
  const [promo, setPromo] = useState("");
  const [sale, setSale] = useState("");
  const [allPromo, setAllPromo] = useState([]);
  const [edit, setEdit] = useState<any>('')
  const router=useNavigate()
  const [admin, setAdmin]=useState<any>(null)
    useEffect(()=>{
        const cartItem:any=localStorage.getItem('admin');
        const cProducts:any=JSON.parse(cartItem)
        setAdmin(cProducts)
    },[])
  useEffect(() => {
    getData();
  }, []);

  async function saveData() {
     await axios.post(`users/addPromo`, { name: promo, sale: sale });
     setPromo('')
     setSale('')
    getData();
 
  }
  async function getData() {
    const res = await axios.get(`users/getPromo`);
    setAllPromo(res.data);
  }
  async function editPromo(id: number) {
    await axios.put(`users/editPromo/${id}`, { name: promo, sale: sale });
    getData()
    setEdit('')
    setPromo('')
    setSale('')
  }
  async function deletePromo(id: number) {
    await axios.delete(`users/deletePromo/${id}`);
    getData()
  }
  return (
    <>
        <Container>
       {admin && <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-2 mt-5 mb-10  md:items-center">
        <div onClick={()=>router('/Admin/AdminProduct')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Продукты</div> 
       <div onClick={()=>router('/Admin/AdminOrders')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]"> Заказы</div>
             <div onClick={()=>router('/Admin/PromoCode')} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Добавить Промокод</div>
        </div>
        }
      </Container>
    <div className="flex justify-center items-center flex-col">
     
      <div className="flex justify-center items-center flex-col">
        <h2 className="text-[30px] text-red-900 mt-5 mb-5">Добавить промокод</h2>
        <label htmlFor="">Промокод</label>
        <input
          className="py-1 px-2 border-[2px] mt-2 rounded-md"
          type="text"
          onChange={(e) => {
            setPromo(e.target.value);
          }}
        />
        <label htmlFor="">Скидка в %</label>
        <input
          className="border-red py-1 px-2 border-[2px] rounded-md"
          type="text"
          onChange={(e) => {
            setSale(e.target.value);
          }}
        />
        <button className="w-[200px] bg-red-200 mt-5  py-1 px-2 border-[1px] rounded-md"
          onClick={() => {
            saveData();
          }}
        >
          Сохранить
        </button>
      </div>
      <div className="w-[60%] flex flex-wrap justify-between m-auto  mt-10">
        {allPromo?.map((el: any) => (
          <div className="border-red mt-5 border-2 p-2">
            {edit.id === el.id ? <div> <input
              className="border-red border-2"
              value={promo}
              type="text"
              onChange={(e) => {
                setPromo(e.target.value);
              }}
            /> <input
            className="border-red border-2"
            value={sale}
            type="text"
            onChange={(e) => {
              setSale(e.target.value);
            }}
          /></div> : <div className="flex justify-between"><p className="mr-[10px]">Промокод-{el.name}</p> <p>Скидка-{el.sale} %</p>  </div>}
            <div className="flex justify-between">
              <div
                className="cursor-pointer hover:scale-105"

              >
                {edit?.id === el.id ? <><MdCheckBox size={20} onClick={() =>  editPromo(el.id) } /></> : <><MdEditSquare size={20} onClick={() => { setEdit(el); setPromo(el.name); setSale(el.sale) }} /></>
                }
              </div>
              <div
                className="cursor-pointer hover:scale-105"

              >
                {edit.id === el.id ? <MdClose size={20} onClick={(e) => setEdit('')} /> : <AiTwotoneDelete size={20} onClick={(e) => deletePromo(el?.id)} />}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};
export default AdminPromo;