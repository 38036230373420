import Container from "../../components/Container";
import FormWrap from "../../components/FormWrap";
import CashSuccesForm from "./CashSuccesForm";




const CashSucces = () => {


    

   const numberorder=Date.now()

    
    return ( <Container>
        <FormWrap>
            <CashSuccesForm number={numberorder} />
        </FormWrap>
    </Container> );
}
 
export default CashSucces;