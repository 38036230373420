import {AppDispatch} from "./index"
import {  CartProductType } from "../models"
import {productDetailSlice} from "./slices/ProductDetailSlice";
import { productidSlice } from "./slices/ProductidSlice";
import axios from "../axios";


export const fetchProduct = () => {


  
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(productDetailSlice.actions.productFetching())
      const response = await axios.get<CartProductType>(`products/fullProducts`)
      dispatch(productDetailSlice.actions.productFetchingSuccess(response.data))
    } catch (e) {
      dispatch(productDetailSlice.actions.productFetchingError(e as Error))
    }
  }
}

export const fetchproductDetail = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(productidSlice.actions.productidFetching())
      const response = await axios.get<CartProductType>(`products/getOne/${id}`)
      dispatch(productidSlice.actions.productidFetchingSuccess(response.data))
    } catch (e) {
      dispatch(productidSlice.actions.productidFetchingError(e as Error))
    }
  }
}



