
import Container from "../../components/Container";

const Policy = () => {
    return ( 
    
        <Container>
    
    <div className="grid drid-cols-1 mx-auto text-[10px] md:text-[18px] w-[380px] md:w-[70%]">

<h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">Политика конфиденциальности</h1>

<h2 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">1. Общие положения</h2>
<h3>1.1. Настоящая Политика конфиденциальности (далее — «Политика») действует в отношениях
между Пользователями и ИП Степанян М.С. и/или его аффилированными лицами, а также
всеми лицами, </h3>

<h3>1.2. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</h3>
<h3>1.2.1. персональные данные, которые Пользователь предоставляет о себе (фамилию, имя,
отчество; дата рождения; пол; адрес доставки; номер телефона; адрес электронной почты и иные
сведения) во время использования им любого из сайтов, сервисов, служб и программ Компании</h3>
<h3>1.2.2. копия паспорта или иного документа, удостоверяющего личность клиента.</h3>
<h3>1.3. Компания не проверяет достоверность персональной информации, предоставляемой
Пользователями. Однако Компания исходит из того, что Пользователь предоставляет
достоверную и достаточную персональную информацию, и поддерживает эту информацию в
актуальном состоянии.</h3>
<h3>1.4. Компания может осуществлять аудиозапись телефонного разговора с Пользователем,
независимо от получения и/или неполучения от Пользователя согласия на аудиозапись
телефонного разговора, если указанные действия необходимы Компании для исполнения своих
обязательств, оценки качества используемых Сервисов и оказанных Пользователю услуг, а также
для достижения целей обработки персональных данных, перечисленных в пункте</h3>
<h3>2.1. настоящей Политики.</h3>
<h3>1.5. Не могут быть отнесены к персональным данным Пользователя такие данные, как: номер
телефона и адрес электронной почты, если обработка этих данных осуществляется обезличенно,
то есть без привязки к персональным данным конкретного Пользователя, его истории заказов
товаров Компании.</h3>
<h3>1.6. Данные, которые автоматически передаются Сервисам в процессе их использования с
помощью установленного на устройстве Пользователя программного обеспечения, в том числе
IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с
помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой
страницы не являются персональными данными.</h3>
<h3>1.7. Настоящая Политика применима только к Сервисам Компании. Компания не контролирует и
не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по
ссылкам, доступным на сайтах Компании. На таких сайтах у Пользователя может собираться или
запрашиваться иная персональная информация, а также могут совершаться иные действия.</h3>
<h3>1.8. Под обработкой персональных данных в настоящей Политике понимается: сбор
вышеуказанных данных, их систематизация, накопление, хранение, уточнение (обновление,
изменение), использование, блокирование, уничтожение</h3>
<h2 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">2. Цели сбора и обработки персональной информации Пользователей</h2>
<h3>2.1. Персональную информацию Пользователя Компания может обрабатываться в следующих
целях:</h3>
<h3>2.1.1. идентификация Пользователя при исполнении Публичной оферты о продаже товаров
дистанционным способом и договоров с Компанией;</h3>
<h3>2.1.2. направление кассового чека в электронном виде на адрес электронной почты и/или телефон
Пользователя;</h3>
<h3>2.1.3. предоставление Пользователю персонализированных Сервисов;</h3>
<h3>2.1.4. связь с Пользователем, в том числе направление уведомлений, запросов и информации,
касающихся использования Сервисов, оказания услуг по заказу и доставке товаров Компании, а
также обработка запросов и заявок от Пользователя;</h3>
<h3>2.1.5. улучшение качества Сервисов, удобства их использования, разработка новых Сервисов и
услуг;</h3>
<h3>2.1.6. рекламы своих товаров и услуг;</h3>
<h3>2.1.7. проведение статистических и иных исследований на основе предоставленных данных;</h3>
<h3>2.1.8. иных целях, не противоречащих действующему законодательству РФ</h3>
<h2 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">3. Условия обработки персональной информации Пользователя и её
передачи третьим лицам</h2>
<h3>3.1. Использование Сервисов означает согласие Пользователя с Политикой. В случае несогласия
с этими условиями Пользователь должен воздержаться от использования Сервисов.</h3>
<h3>3.2. Согласие Пользователя на предоставление Персональной информации, данное им в
соответствии с настоящей Политикой в рамках отношений с одним из лиц, входящих в Компанию,
распространяется на все лица, входящие в Компанию.</h3>
<h3>3.3. Согласие Пользователя на обработку его персональных данных действует со дня начала
пользования Сервисов до дня отзыва согласия, посредством обращения Пользователя к
представителям (операторам) по телефону 89000284222, либо посредством
направления Пользователем письма по электронной почте на адрес hovonn55nnovoh@gmail.com.</h3>
<h3>3.4. Пользователь вправе запросить перечень своих персональных данных и/или потребовать
изменить, уничтожить свои персональные данные, позвонив по телефону 89000284222 либо
посредством направления Пользователем письма по электронной почте на адрес
hovonn55nnovoh@gmail.com и указав свои имя, отчество, фамилию, дату рождения, адрес доставки,
телефон и адрес электронной почты.</h3>
<h3>3.5. Обработка таких персональных данных, как: фамилия, имя, отчество; дата рождения; пол;
адрес доставки, телефон, адрес электронной почты может осуществляться оператором Компании
независимо от получения и/или неполучения от Пользователя согласия на их обработку, если
указанные персональные данные необходимы Компании для выполнения заказа и доставки
товаров Пользователю и оценки качества оказанных услуг.</h3>
<h3>3.6. Компания хранит персональную информацию Пользователей, обеспечивая их
конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.</h3>
<h3>3.7. Компания вправе передать персональную информацию Пользователя третьим лицам в
следующих случаях:</h3>
<h3>3.7.1. передача необходима в рамках использования Пользователем определенного Сервиса,
либо для оказания услуги Пользователю;</h3>
<h3>3.7.2. такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в
части), при этом к приобретателю переходят все обязательства по соблюдению условий
настоящей Политики применительно к полученной им персональной информации;</h3>
<h3>3.7.3. в целях обеспечения возможности защиты прав и законных интересов Компании или третьих
лиц в случаях, когда Пользователь нарушает условия Публичной оферты;</h3>
<h3>3.7.4. в целях доставки товаров Пользователю посредством курьерской службы Компании;</h3>
<h3>3.7.5. в целях сохранения и проведения анализа истории заказов товаров Пользователем
посредством использования операторов Компании, осуществляющих прием заказов товаров по
телефону;</h3>
<h3>3.7.6. в целях совершения операторами Компании и иными уполномоченными представителями
Компании исходящих опросов и вызовов в адрес Пользователя в целях проведения качественного
и количественного анализа предоставляемого Сервиса и оказанных услуг, исследования
предпочтений Пользователя, проведения маркетинговых исследований, проведение розыгрышей
призов среди Пользователей, оценки удовлетворенности Пользователя Сервисами и услугами
Компании, урегулирования конфликтных ситуаций</h3>
<h3>3.8. Компания имеет право назначить лицо (оператора Компании), ответственное за организацию
обработки персональных данных Пользователей, в целях реализации положений настоящей
Политики.</h3>
<h3>3.9. Компания вправе самостоятельно определять используемые способы обработки
персональных данных Пользователей (включая, но, не ограничиваясь: автоматическая сверка
почтовых кодов с базой кодов/индексов, автоматическая проверка написания названий
улиц/населенных пунктов, сегментация базы данных по заданным критериям).</h3>
<h2 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">4. Изменение Политики конфиденциальности</h2>
<h3>4.1. Компания имеет право вносить изменения в настоящую Политику. При внесении изменений в
актуальной редакции указывается дата последнего обновления. Пользователь обязуется
самостоятельно контролировать наличие изменений в настоящей Политике. Новая редакция
Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой
редакцией Политики. Действующая редакция всегда находится на Сайте.</h3>
<h2 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">5. Заключительные положения</h2>
<h3>5.1. Настоящая Политика регулируется нормами действующего законодательства РФ.</h3>
<h3>5.2. Все возможные споры относительно настоящей Политики разрешаются согласно нормам
действующего законодательства РФ.</h3>
    </div> 
    </Container> );
}
 
export default Policy;