
import { CartProductType } from "../models";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";


type CartContextType ={
    cartTotalQty: number,
    cartTotalAmount:number,
    cartProducts:CartProductType[]|null,
    handleAddProductCart:(product:CartProductType)=>void
    handleRemoveProductFromCart:(product:CartProductType)=>void,
    handleCartQtyInCrase:(product:CartProductType)=>void,
    handleCartQtyDeCrase:(product:CartProductType)=>void,
    handleClearCart:()=>void,
}


export const CartContext=createContext<CartContextType | null>(null)

interface Props{
    [propName:string]:any
}

export const CartContextProvider=(props:Props)=>{
    
    const [cartTotalQty, setCartTotalQty]=useState(0);
    const [cartProducts, setCartProducts]=useState<CartProductType[]|null>(null)
    const [cartTotalAmount, setCartTotalAmount]=useState(0)




    useEffect(()=>{
        const cartItem:any=localStorage.getItem('proditem');
        const cProducts:any=JSON.parse(cartItem)
        setCartProducts(cProducts)
    },[])


    useEffect(()=>{
       const getTotals=()=>{

        if(cartProducts){

           const {total, qty} = cartProducts?.reduce((acc, item)=>{
                const itemTotal=item?.price * item?.quantity

                acc.total += itemTotal
                acc.qty += item?.quantity

                return acc
            },{
                total:0,
                qty:0
            })
            setCartTotalQty(qty)
            setCartTotalAmount(total)
        }
        }
        getTotals()
    },[cartProducts])

    const handleAddProductCart=useCallback((product:CartProductType)=>{
        setCartProducts((prev)=> {
            let updatedCart;

            if(prev){
                updatedCart=[...prev, product]
            }else{
                updatedCart=[product]
            }
         
            localStorage.setItem('proditem', JSON.stringify(updatedCart))

            return updatedCart;
        })
    },[])
    
    

    const handleRemoveProductFromCart=useCallback((product:CartProductType)=>{

        if(cartProducts){
            const filteredProducts=cartProducts.filter((item)=>{
                return item.id !== product.id
            })

            setCartProducts(filteredProducts)
            localStorage.setItem('proditem', JSON.stringify(filteredProducts))
        }
       
    },[cartProducts])


    const handleCartQtyInCrase= useCallback((product:CartProductType)=>{

        let updatedCart;

        if(product.quantity===999){
            return toast.error("Очень много")
        }

        if(cartProducts){
            updatedCart=[...cartProducts]

            const existingIndex=cartProducts.findIndex((item)=>item.id===product.id)
           if(existingIndex > -1){
            updatedCart[existingIndex].quantity=++updatedCart[existingIndex].quantity
           }

           setCartProducts(updatedCart)
           localStorage.setItem('proditem', JSON.stringify(updatedCart))
        }

    },[cartProducts])

    const handleCartQtyDeCrase= useCallback((product:CartProductType)=>{

        let updatedCart;

        if(product.quantity===1){
            return toast.error("Очень мало")
        }

        if(cartProducts){
            updatedCart=[...cartProducts]

            const existingIndex=cartProducts.findIndex((item)=>item.id===product.id)
           if(existingIndex > -1){
            updatedCart[existingIndex].quantity=--updatedCart[existingIndex].quantity
           }

           setCartProducts(updatedCart)
           localStorage.setItem('proditem', JSON.stringify(updatedCart))
        }

    },[cartProducts])

    const handleClearCart=useCallback(()=>{
        setCartProducts(null);
        setCartTotalQty(0)
        localStorage.setItem('proditem', JSON.stringify(null))
    },[cartProducts])

    const value={
        cartTotalQty,
        cartTotalAmount,
        cartProducts,
        handleAddProductCart,
        handleRemoveProductFromCart,
        handleCartQtyInCrase,
        handleCartQtyDeCrase,
        handleClearCart,
    }

    return <CartContext.Provider value={value} {...props}/>
    
}

export const useCart=()=>{
    const context=useContext(CartContext)

    if(context===null){
        throw new Error("Contxet error")
    }


    return context
}