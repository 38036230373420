
import { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { useAppSelector } from "../hooks/redux";

const SearchBar = ({input,setInput,setSearch, product5}:any) => {

  const [product1, setProduct]=useState<any>()
  const {product} = useAppSelector(state => state.productDetailReducer)

  useEffect(() => {
  
   if(input?.length>3){
   setProduct(product5? product5:product)
   }
  
 }, [input])

 useEffect(()=>{
 
 if(product1?.length>0&&input.length){
  const result=product1?.filter((prod:any)=>{
    
    return prod  && prod?.name?.toLowerCase().includes(input?.toLowerCase())
    
  })
  setSearch(result)
  setProduct('')
 }

 },[product1])

 const handleSubmit=(e:any, value:any)=>{

  // const product2=[product]

  // e.preventDefault()
  // const result=product2?.filter((prod:any)=>{
    
  //   return prod  && prod?.name?.toLowerCase().includes(value?.toLowerCase())
    
  // })
  // setSearch(result)

 }



  const handleChange=(e:any, value:string)=>{
      e.preventDefault()
   
      if(!value?.trim().length) return;
      setInput(value)
      console.log(value)
      handleSubmit(e, value.toLowerCase())
  }

  return ( <form onSubmit={(e)=>handleChange(e, input)}>   
    <label form="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <IoMdSearch/>
        </div>
        <input type="search" id="default-search" value={input? input: ''} onChange={(e)=>{handleChange(e, e.target.value);setInput(e.target.value);
        }}
        className="block w-full p-4 ps-10 text-sm text-gray-900 border rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
         placeholder="Анкер с крючком" required/>
    </div>
</form> );
}
 
export default SearchBar;