

import { CartProductType } from "../../models"

interface SetQtyProps{
    cartCounter?:boolean,
    cartProduct:CartProductType
    handleQtyIncrase:()=>void,
    handleQtyDecrase:()=>void

}

const btnStyles='border-[0.1px] border-slate-500 px-[2px] rounded'

const SetQuantity: React.FC<SetQtyProps> = ({ cartProduct, cartCounter,  handleQtyDecrase, handleQtyIncrase,}) => {
    return ( <div className="flex gap-8 items-center">
        {cartCounter ? null : <div className="font-semibold">
            КОЛИЧЕСТВО:
        </div> }
        <div className="flex gap-4 items-center  w-[2px] mr-5 h-1 md:text-base">
            <button onClick={handleQtyDecrase} className={btnStyles}>-</button>
            <div className="">{cartProduct?.quantity}</div>
            <button onClick={handleQtyIncrase} className={btnStyles}>+</button>
        </div>
    </div> );
}
 
export default SetQuantity;