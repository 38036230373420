
import { useEffect, useState } from "react";
import Input from "../../components/inputs/input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { LoginAuth } from "../../api";

const LoginForm = () => {


    const [isLoading, setISLoading] = useState(false)
    const [auth, setAuth] = useState<any>("");

    const { register, handleSubmit, formState: { errors } } = useForm<FieldValues>({
        defaultValues: {
            email: '',
            password: ''
        }
    })
    const router=useNavigate()

    const onsubmit:SubmitHandler<FieldValues> = (data)=>{
       
        LoginAuth(
          {
            email: data.email,
            password: data.password,
          },
          setAuth
        );
        // setISLoading(true)
        // if(auth?.role){
        //     router.push('./admin')
        // }else{
        // setISLoading(false)
        // toast.error("неправильны логин или пароль")
        // }
        // return
        setISLoading(false)

    }
    
  useEffect(() => {
   
     localStorage.setItem('admin',JSON.stringify(auth))
    if (auth?.role === "admin") {
     
      router("/Admin");
      
      router(0)
    }    
 
   
  }, [auth]);

    return (<>

        <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-4 ">Войти</h1>
        <hr className="bg-slate-300 w-full h-px" />
        <Input id="email" label='Email или Логин' disabled={isLoading} register={register} errors={errors} required />
        <Input id="password" label='Парол' disabled={isLoading} register={register} errors={errors} required type="password" />
        <Button label={isLoading ? "Загрузка данных" : "Войти"} onClick={handleSubmit(onsubmit)} />
    </>);
}

export default LoginForm;