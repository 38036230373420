
import { useCart } from "../../hooks/useCart";
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CiShoppingCart } from 'react-icons/ci'
import { RiAdminFill } from "react-icons/ri";
import { RiFilePaper2Fill } from "react-icons/ri";

const CartCount = () => {

    const { cartTotalQty } = useCart()
    const router = useNavigate()
    const [admin, setAdmin]=useState<any>(null)
    const [order, setOrder]=useState<any>(null)

    useEffect(()=>{
        const cartItem:any=localStorage.getItem('admin');
        const cProducts:any=JSON.parse(cartItem)
        setAdmin(cProducts)
    },[])


    useEffect(()=>{
        const orderItem:any=localStorage.getItem('myOrders');
        const orderProducts:any=JSON.parse(orderItem)
        setOrder(orderProducts)
    },[])

    return (
        <>
            {admin && <Link to='/Admin' className="text-3xl">
                <RiAdminFill />
            </Link>}
            {order && <Link to='/CustmerOrder' className="text-3xl">
            <RiFilePaper2Fill />
            </Link>}
            <div className=" flex relative cursor-pointer" onClick={() => router('/cart')}>

                <span className=" absolute top-[-10px] right-[-10px] bg-slate-800 text-white h-6 w-6 rounded-full flex items-center justify-center text-sm">
                    {cartTotalQty}
                </span>
                <div className="text-3xl">
                    <CiShoppingCart />
                </div>

            </div></>);
}

export default CartCount;