import {CartProductType} from "../../models";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProductDetailState {
  productDetail: CartProductType | null
  loading: boolean
  error: string
}

const initialState: ProductDetailState = {
  productDetail: null,
  loading: false,
  error: ''
}

export const productidSlice = createSlice({
  name: 'productid',
  initialState,
  reducers: {
    productidFetching(state) {
      state.loading = true
    },
    productidFetchingSuccess(state, action: PayloadAction<CartProductType>) {
      state.loading = false
      state.error = ''
      state.productDetail = action.payload
    },
    productidFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.error = action.payload.message
    }
  }
})

export default productidSlice.reducer