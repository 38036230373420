import { useEffect, useState } from "react";
import { PiCheckCircleBold } from "react-icons/pi";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { truncateText } from "../../utils/truncateText";
import { AddOrders } from "../../api";

const CashSuccesForm = (number:any) => {

    const router=useNavigate()
    const [prodItem, setProdItem]=useState<any>([])
    const [deliveri, setDeliveri]=useState()
    const [userItem, setUserItem]=useState<any>()
    const [price, setPrice]=useState(0)
    const numberorder=number
    useEffect(()=>{
        const prod:any=localStorage.getItem("proditem")
        const prod1=JSON.parse(prod)
        setProdItem(prod1)
    },[])
    useEffect(()=>{
        const user:any=localStorage.getItem('user')
        const user1=JSON.parse(user)
        setUserItem([user1[0]])
        setDeliveri(user1[2])
        setPrice(user1[1])
    },[])

    

  async function AddOrder(data1:any){

    

        let dataname:any=data1[2].map((item:any)=>{
            let prod={
                id:item.id,
                prodname:item.name,
                prodprice:item.price,
                prodsize:item.size,
                prodquantity:item.quantity,
                prodweight:item.brand
            }
            return prod
        } )
        const data={
            order_id:numberorder.number,
            ready:false,
            completed:false,
            checkout:"cash",
            custmer:data1[0],
            product:dataname,
            deliveri:data1[1],
            price
        }
       
        await AddOrders(data)
        localStorage.setItem("myOrders", JSON.stringify([numberorder.number]))
        router('/')
    }



    return (
    <>
        <div><PiCheckCircleBold size={80}/></div>
        <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-2 ">Ваш заказ обрабатываеться</h1>
        <h3 className="flex flex-col gap-1 items-center w-full text-base mt-[-10px]  md:text-lg">Скоро с вами связаться наш оператор</h3>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="w-[80%] md:w-[50%] ">
            {prodItem?.map((item:any, i:number)=><div key={item.id} className="flex  w-full">
                <div className="mr-2">{i+1}.</div>
                <div className="mr-1">{truncateText(item?.name)}<span className="ml-2">-</span></div>
                <div>{item.quantity}<span className="ml-1">-{item.brand}</span></div>
            </div>)}
            <hr className="bg-slate-300 w-full h-px mt-2" />
        </div>
        <div className="flex mt-[-15px] w-full justify-end items-end" >Итого : <span className="ml-2">{price} рублей</span></div>
        <hr className="bg-slate-300 w-full h-px" />
        <h3 className="flex flex-col gap-1 items-center w-full text-base mt-[-20px]  mb-[-10px] md:text-lg ">Ваши данные</h3>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="w-[90%] md:w-[50%] mt-[-10px] ">
            {userItem?.map((item:any, i:number)=><div key={i} className="flex flex-col w-full">
                <div className="w-full text-[15px]"><span className="mr-2">Фамилия заказчика:</span >{item?.firstname}</div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div  className="w-full text-[15px]"><span className="mr-2">Имя заказчика:</span>{item?.name}</div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[14px]"><span className="mr-2">Телефон заказчика:</span>{item?.phone}</div>
                {item?.city && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Адресс доставки:</span>{item?.city}</div>
                </div>}
                {item?.strit && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Улица или район:</span><span className={item.strit.length > 10 ? "text-[10px] md:text-[15px]":""}>{item.strit}</span></div>
                </div>}
                {item?.home && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Дом, квартира:</span>{item?.home}</div>
                </div>}
            </div>)}
            <hr className="bg-slate-300 w-full h-px mt-2" />
        </div>
        <h4 className="flex text-center text-[10px] w-[80%] mt-[10px] md:font-semibold  md:text-[12px] items-center ml-3 md:ml-20 ">Пожалуйста убедитесь что ваши данные заполнени правильно иначе заказ не будет оформлен</h4>
        <Button label="Вернуться на главную" onClick={()=>AddOrder([userItem, deliveri, prodItem ])} />
    </>);
}

export default CashSuccesForm;