import { useEffect, useState } from "react";
import { GiPayMoney } from "react-icons/gi";
import Button from "../../components/Button";
import { useNavigate, Link} from "react-router-dom";
import { truncateText } from "../../utils/truncateText";
// import axios from "axios";
import Loading from "../../components/Loading";
import axios from "../../axios";
// import { API_URL } from "../../config";


const PaymentForm = () => {

    const router=useNavigate()
    const [prodItem, setProdItem]=useState<any>([])
    const [deliveri, setDeliveri]=useState()
    const [userItem, setUserItem]=useState<any>()
    const [price, setPrice]=useState(0)
    const [loading, setLoading]=useState(false)
    const [item,setItem] =useState<any>()
  
    const res:any = localStorage.getItem('id')
    const res1:any= localStorage.getItem('proditem')
    const user:any= localStorage.getItem('user')
    
  
    let orderId= JSON.parse(res)
    let amount= JSON.parse(res1)
    let customer= JSON.parse(user)


    let dataname:any=amount.map((item:any)=>{
        let prod={
            id:item.id,
            prodname:item.name,
            prodprice:item.price,
            prodsize:item.size,
            prodquantity:item.quantity,
            prodweight:item.brand
        }
        return prod
    } )
   
    let data={
        order_id:orderId,
        ready:false,
        completed:false,
        checkout:"online",
        custmer:[customer[0]],
        product:dataname,
        deliveri:customer[2],
        price:customer[1],
        paymentId:''
       
    }
    
  useEffect(()=>{
 if(item?.confirmationUrl){

    window.location.replace(`${item?.confirmationUrl}`);
 }
 

    
  },[item])


    useEffect(()=>{
        const prod:any=localStorage.getItem("proditem")
        const prod1=JSON.parse(prod)
        setProdItem(prod1)
    },[])
    useEffect(()=>{
        const user:any=localStorage.getItem('user')
        const user1=JSON.parse(user)
        setUserItem([user1[0]])
        setDeliveri(user1[2])
        setPrice(user1[1])

    },[])

    
   
    

   async function AddOrder(data1:any){
    
        setLoading(true)
        await axios.post(`orders/addOrders`, data)
        try {
            
          const response = await  axios.post(`payment/create-payment`,{
            amount:customer[1],
            description: `Заказ №${orderId}`,
            orders_id:orderId
        }).then((res)=>{setItem(res?.data)});
          
       
      
        } catch (error) {
          console.error(error)
        }
  
  
       
        
    }



    return (
    <>{loading?<><Loading/></>:<>
        <div><GiPayMoney size={80}/></div>
        <h1 className="flex flex-col gap-1 items-center w-full text-base font-semibold md:text-lg mt-2 ">Ваш заказ обрабатываеться</h1>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="w-[80%] md:w-[50%] ">
            {prodItem?.map((item:any, i:number)=><div key={item.id} className="flex  w-full">
                <div className="mr-2">{i+1}.</div>
                <div className="mr-1">{truncateText(item?.name)}<span className="ml-2">-</span></div>
                <div>{item.quantity}<span className="ml-1">-{item.brand}</span></div>
            </div>)}
            <hr className="bg-slate-300 w-full h-px mt-2" />
        </div>
        <div className="flex mt-[-15px] w-full justify-end items-end" >Итого : <span className="ml-2">{price} рублей</span></div>
        <hr className="bg-slate-300 w-full h-px" />
        <h3 className="flex flex-col gap-1 items-center w-full text-base mt-[-20px]  mb-[-10px] md:text-lg ">Ваши данные</h3>
        <hr className="bg-slate-300 w-full h-px" />
        <div className="w-[90%] md:w-[50%] mt-[-10px] ">
            {userItem?.map((item:any, i:number)=><div key={i} className="flex flex-col w-full">
                <div className="w-full text-[15px]"><span className="mr-2">Фамилия заказчика:</span >{item.firstname}</div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div  className="w-full text-[15px]"><span className="mr-2">Имя заказчика:</span>{item.name}</div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[14px]"><span className="mr-2">Телефон заказчика:</span>{item.phone}</div>
                {item.city && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Адресс доставки:</span>{item.city}</div>
                </div>}
                {item.strit && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Улица или район:</span><span className={item.strit.length > 10 ? "text-[10px] md:text-[15px]":""}>{item.strit}</span></div>
                </div>}
                {item.home && <div>
                <hr className="bg-slate-300 w-full h-px mt-2" />
                <div className="w-full text-[15px]"><span className="mr-2">Дом, квартира:</span>{item.home}</div>
                </div>}
            </div>)}
            <hr className="bg-slate-300 w-full h-px mt-2" />
        </div>
        <h4 className="text-[10px] w-[80%] mt-[-20px] md:font-semibold  md:text-[12px] items-center ml-3 md:ml-20">Пожалуйста убедитесь что ваши данные заполнени правильно иначе заказ не будет оформлен</h4>
        <Button label="Перейти сайт оплаты" onClick={()=>AddOrder([userItem, deliveri, prodItem, res ])} />
        {item?.confirmationUrl && <Link to={`${item?.confirmationUrl}`}>okk</Link>}
    </>}</>);
}

export default PaymentForm;