import { useParams } from "react-router-dom";
import Container from "../../components/Container";
import ProductDetails from "./ProductDetails";




const ProductCard = () => {

    const {id} = useParams();
  
    
    return (<div className="p-4">
     <Container>
        <ProductDetails productId={id}/>
     </Container>
    </div>);
}
 
export default ProductCard;