import {CartProductType} from "../../models";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProductDetailState {
  product: CartProductType | null
  loading: boolean
  error: string
}

const initialState: ProductDetailState = {
  product: null,
  loading: false,
  error: ''
}

export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    productFetching(state) {
      state.loading = true
    },
    productFetchingSuccess(state, action: PayloadAction<CartProductType>) {
      state.loading = false
      state.error = ''
      state.product = action.payload
    },
    productFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.error = action.payload.message
    }
  }
})

export default productDetailSlice.reducer