import {combineReducers, configureStore} from "@reduxjs/toolkit"
import productDetailReducer from './slices/ProductDetailSlice'
import productidReducer from './slices/ProductidSlice'


const rootReducer = combineReducers({

  productDetailReducer,
  productidReducer,
})

export function setupStore() {
  return configureStore({
    reducer: rootReducer
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']