export const truncateText =(str:string)=>{
    if(str?.length<20 ) return str;

    return str.substring(0, 13) + "..."
}

export const truncateText1 =(str:string)=>{
    if(str?.length<20 ) return str;

    return str?.substring(0, 10) + "..."
}